/**
 * Vérifie si le format d'une adresse email est correct
 *
 * @param {String} mail
 *
 * @return {Boolean}
 */
export default function(mail) {
  if (typeof mail !== 'string') return null

  for (let idx = 1; idx < mail.length; idx++) {
    if (mail.charAt(idx) == '@') {
      if (idx < mail.length - 4) {
        for (var __idx = idx; __idx < mail.length - 2; __idx++) {
          if (mail.charAt(__idx) == '.') {
            return true
          }
        }
      }
    }
  }

  return false
}
