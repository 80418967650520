import Vue from 'vue'
import Vuex from 'vuex'

import store_auth from './modules/store_auth'
import store_csrf from './modules/store_csrf'
import store_debug from './modules/store_debug'
import store_check_form from './modules/store_check_form'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    store_auth,
    store_csrf,
    store_debug,
    store_check_form,
  },
})

export default store
