import provider from '../../services/provider'
import { getRandomStr, getCsrfToken, setCsrfToken } from '@/modules/functions'

export default {
  namespaced: true,
  state: () => ({
    token: 'default',
  }),
  getters: {},
  mutations: {
    token(state, payload) {
      state.token = payload || ''
    },
  },
  actions: {
    async test({ commit, state }) {
      commit('token', getRandomStr())
      setCsrfToken(state.token)
      console.log('token: ' + getCsrfToken())
      return 'token: ' + getCsrfToken()
    },
    async get({ commit, state }) {
      const response = await provider.get('api/csrftoken')

      if (response.data['csrf_token']) {
        commit('token', response.data['csrf_token'])
        setCsrfToken(state.token)
        // console.log('token: ' + state.token)
      } else {
        // clé manquante
        commit('token', '')
        setCsrfToken(state.token)
        console.error('csrf_token missing')
      }
    },

    async delete({ commit, state }) {
      const deleteCsrfToken = getCsrfToken()

      const response = await provider.delete('api/csrftoken', {
        deleteCsrfToken,
      })
      commit('token', '')
      setCsrfToken(state.token)
      console.log('csrf_token deleted')
    },
  },
}
