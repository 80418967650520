import provider from '../../services/provider'

export default {
  namespaced: true,
  state: () => ({
    usernameAvailable: false,
    overlayState: false,
  }),
  mutations: {
    usernameAvailable(state, payload) {
      state.usernameAvailable = payload || false
    },
    overlayState(state, payload) {
      state.overlayState = payload || false
    },
  },
  actions: {
    async usernameAvailable({ commit }, username) {
      const response = await provider.get('check/username', { username })
      commit('usernameAvailable', response.data && response.data['userIsValid'])
    },
    overlayState({ commit }, state) {
      commit('overlayState', state)
    },
  },
}
