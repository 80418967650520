import provider from '../../services/provider'
import router from '../../router/index'

import { getCsrfToken } from '@/modules/functions'

export default {
  namespaced: true,
  state: () => ({
    isLogged: false,
    isAdmin: false,
    username: null,
    firstname: null, // not available, maybe later implementation
    lastname: null, // not available, maybe later implementation
  }),
  mutations: {
    isLogged(state, payload) {
      state.isLogged = payload || false
    },
    isAdmin(state, payload) {
      state.isAdmin = payload || false
    },
    username(state, payload) {
      state.username = payload || 'anonymous'
    },
  },
  actions: {
    async login({ commit }, form) {
      const sendData = {
        user: form.username,
        password: form.password,
        captcha: form.captcha,
        csrf_token: getCsrfToken(), // Formulaire donc ajoute le jeton CSRF stocké dans le header
      }

      const response = await provider.post('auth/login', sendData)
      this.dispatch('store_debug/set', sendData)

      const isLoggedIn = response.data['isLoggedIn'] || false
      if (isLoggedIn && typeof isLoggedIn === 'boolean') {
        commit('isLogged', response.data['isLoggedIn'])
        commit('isAdmin', response.data['isAdmin'] || false)

        router.push('/dashboard')
      } else {
        commit('isLogged', false)
      }
    },

    async register({ commit }, form) {
      const sendData = {
        user: form.username_reg,
        password1: form.password1,
        password2: form.password2,
        email1: form.email1,
        email2: form.email2,
        firstname: form.firstname,
        lastname: form.lastname,
        captcha: form.captcha,
        csrf_token: getCsrfToken(), // Formulaire donc ajoute le jeton CSRF stocké dans le header
      }

      const response = await provider.post('auth/register', sendData)
      this.dispatch('store_debug/set', sendData)

      const isRegistered = response.data['isRegistered'] || false
      if (isRegistered && typeof isRegistered === 'boolean') {
        router.push('/login')
      } else {
      }
    },

    async password_recovery({ commit }, form) {
      const sendData = {
        user: form.username_reg,
        email: form.email1,
        captcha: form.captcha,
        csrf_token: getCsrfToken(), // Formulaire donc ajoute le jeton CSRF stocké dans le header
      }

      const response = await provider.post('auth/password_recovery', sendData)
      this.dispatch('store_debug/set', sendData)

      const isNewPasswordSend = response.data['isNewPasswordSend'] || false
      if (isNewPasswordSend && typeof isNewPasswordSend === 'boolean') {
        router.push('/login')
      } else {
      }
    },
  },
}
