import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    setTimeout(() => {
      const smoothSlide = document.querySelector('#app')
      if (smoothSlide) {
        smoothSlide.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }, 150)
  },
})

function setTitle(value) {
  document.title = value || ''
}
function setMeta(key, value) {
  document.head.querySelector(key).content = value || ''
}
function setLink(key, value) {
  document.head.querySelector(key).href = value || ''
}

router.beforeEach((to, from, next) => {
  const isLogged = store.state.store_auth.isLogged
  const isAdmin = store.state.store_auth.isAdmin
  const requireAuth = to.meta.requires.auth
  const requireAnonymous = to.meta.requires.anonymous
  const requireAdmin = to.meta.requires.admin

  // Requiert d'etre connecté
  if (requireAuth && !isLogged) next('/login')
  // Tentative de connexion à une page de login
  else if (isLogged && requireAnonymous) next('/dashboard')
  // Requiert d'etre admin et connecté
  else if (!isAdmin && requireAdmin) next('/login')
  // Vous avez les autorisations requises
  else {
    setTitle(to.meta.seo.title)
    setMeta('meta[name=description]', to.meta.seo.description)
    setMeta('meta[name=keywords]', to.meta.seo.keywords)
    setLink('link[rel=canonical]', document.location.origin + to.path)

    next()
  }
})

export default router
