import axios from 'axios'
import qs from 'qs'

import { getRandomInt } from '@/modules/functions'

/**
 * Class Provider
 *
 * INFO: Ne pas instancier cette class avec new Provider() !!
 *       Pour instancier cette Class singleton et son Constructor
 *      >const provider = Provider.__construct()
 *      >console.log( typeof provider ) // Object
 *
 * Instance une communication vers une Api
 * et personnalise les requetes
 *
 */
class Provider {
  /**
   * Method Static pour Instanciaton Singleton
   *
   * return {stdClass Object}
   */
  static __instance
  static __construct() {
    if (!Provider.__instance) {
      Provider.__instance = new Provider()
      // Constructor
      Provider.__instance.instance_axios = null
      Provider.__instance.init()
    }

    return Provider.__instance // retourne l'instance à chaque appel de la method static
  }

  /**
   * Créer une instance Axios personnalisé
   *
   * return {void}
   */
  init() {
    this.instance_axios = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: 10000,
      responseType: 'json',
      responseEncoding: 'utf8',
      'Content-Type': 'multipart/form-data',
      xsrfCookieName: process.env.VUE_APP_API_CSRF_COOKIE,
      xsrfHeaderName: 'X-XSRF-TOKEN',
      onUploadProgress: function(progressEvent) {
        console.error('onUploadProgress ' + JSON.stringify(progressEvent))
      },
      onDownloadProgress: function(progressEvent) {
        console.warn('onDownloadProgress ' + JSON.stringify(progressEvent))
      },
      // cancelToken: new CancelToken(function(cancel) {
      //   console.log('cancelToken')
      // }),
    })

    console.warn('Wrap Api init')
  }

  /**
   * Requete une route GET vers une Api
   *
   * @param {String} url
   * @param {Object} params
   *
   * return {Object}
   */
  async get(url = '', params = {}) {
    if (!this.instance_axios) return {}

    params.access_token = localStorage.getItem('access_token')

    try {
      const response = await this.instance_axios.get(url, { params: params })
      return this.buildResponse(response)
    } catch (error) {
      return this.buildResponse({})
    }
  }

  /**
   * Requete une route POST vers une Api
   *
   * @param {String} url
   * @param {Object} params
   *
   * return {Object}
   */
  async post(url = '', params = {}) {
    if (!this.instance_axios) return {}

    params.access_token = localStorage.getItem('access_token')

    try {
      const response = await this.instance_axios.post(url, {
        data: qs.stringify(params),
      })
      return this.buildResponse(response)
    } catch (error) {
      return this.buildResponse({})
    }
  }

  /**
   * Requete une route PUT vers une Api
   *
   * @param {String} url
   * @param {Object} params
   *
   * return {Object}
   */
  async put(url = '', params = {}) {
    if (!this.instance_axios) return {}

    params.access_token = localStorage.getItem('access_token')

    try {
      const response = await this.instance_axios.put(url, {
        data: qs.stringify(params),
      })
      return this.buildResponse(response)
    } catch (error) {
      return this.buildResponse({})
    }
  }

  /**
   * Requete une route DELETE vers une Api
   *
   * @param {String} url
   * @param {Object} params
   *
   * return {Object}
   */
  async delete(url = '', params = {}) {
    if (!this.instance_axios) return {}

    params.access_token = localStorage.getItem('access_token')

    try {
      const response = await this.instance_axios.delete(url, {
        data: qs.stringify(params),
      })
      return this.buildResponse(response)
    } catch (error) {
      return this.buildResponse({})
    }
  }

  /**
   * Construit une réponse d'Api, retourne les champs par défaut défini
   *
   * @param {Object} response
   *
   * return [Object]
   */
  buildResponse(response = {}) {
    const Obj = {
      data: response.data || {},
      status: response.status || 404,
      header: response.headers || {},
    }

    const rnd = getRandomInt(2)

    Obj.data.userIsValid = rnd === 0 ? false : true

    return Obj
  }

  defineAccessToken() {
    if (typeof localStorage.getItem('access_token') === 'object') {
      localStorage.setItem('access_token', '11')
    }
  }
}

export default Provider.__construct() // Singleton
