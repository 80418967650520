<template>
  <div id="app">
    <FormOverlay>
      <Wrapper />
      <Debug />
    </FormOverlay>
  </div>
</template>

<script>
export default {
  components: {
    Wrapper: () => import('./Wrapper'),
    Debug: () => import('./components/Debug'),
    FormOverlay: () => import('./components/FormOverlay'),
  },
}
</script>

<style lang="less">
@MainBg: #333;
@MainColor: white;

body {
  background: @MainBg;
  color: @MainColor;
  margin: 0;
  padding: 0;

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
</style>
