// Exigence d'un mot de passe
// A configurer
const exigence = {
  length: 8,
  upper: 3,
  lower: 3,
  number: 1,
}

/**
 * Vérifie si un mot de passe réspecte les exigences
 *
 * @param {String} password1
 * @param {String} password2
 *
 * @return {Boolean}
 */
export default function(password1, password2) {
  if (typeof password1 === 'string' && password1.length === 0) return null
  const check = {
    matches: password1 === password2,
    size: password1.length,
    uppercase: 0,
    lowercase: 0,
    number: 0,
  }

  for (let i = 0; i < check['size']; i++) {
    if (password1[i] >= 'a' && password1[i] <= 'z') {
      check['lowercase']++
    } else if (password1[i] >= 'A' && password1[i] <= 'Z') {
      check['uppercase']++
    } else if (password1[i] >= '0' && password1[i] <= '9') {
      check['number']++
    }
  }

  if (
    !check['matches'] ||
    check['size'] < exigence['length'] ||
    check['uppercase'] < exigence['upper'] ||
    check['lowercase'] < exigence['lower'] ||
    check['number'] < exigence['number']
  ) {
    return false
  }

  return true
}
